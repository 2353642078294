<template>
  <div style="text-align: center;">
    <van-list>
      <van-image
        style="margin: 25% 35% 3% 35%;"
        width="5rem"
        height="5rem"
        fit="cover"
        :src="newImg"
      />
      <span style="margin: auto 35% auto 35%;">支付成功</span>
      <van-button
        type="primary"
        style="margin: 15% 5% auto 5% ; width: 90%; height: 40%;"
        color="#57c4b7"
        round
        size="large"
        @click="onEnter"
      >确定</van-button>
    </van-list>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>
</template>
<script>
// import { Toast } from "vant";
// import ajax from "../../lib/ajax";
// const OperatorId = "000000";
export default {
  data() {
    return {
      newImg: require("../../assets/PaySuccess.png")
    };
  },
//   created() {
//     this.onload();
//   },
  methods: {
    onEnter() {
      this.$router
        .replace({
          path: "/HCardL"
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>